import React, { useEffect, useState, ReactElement, useRef } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useTypedSelector } from '@hooks';
import {
  OrganizationBoundary,
  OrganizationProvider,
  useOrganization
} from '@components/OrganizationBoundary';
import { useSearchParams, useNavigate, Routes, Route } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import {
  setSubscriptionCreated,
  subscriptionSetCardCreated
} from '@api/Subscription';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { message } from 'antd';
import {
  organizationItemsListStateSelector,
  organizationListStateSelector,
  organizationsStateSelector,
  ownOrganizationItemStateSelector
} from '@redux/selectors/organizations';
import { fetchOrganizationsList } from '@redux/actions/organizations';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import OrganizationLocked from '@components/OrganizationLocked';
import { OnboardingSteps } from '@components/OnboardingSteps';
import EmailConfirmation from '@components/EmailConfirmation';
import { nonNull } from '@helpers/non-null';
import { JoinOrganizationError, joinToOrganization } from '@api/Organization';
import { GtagService } from '@services/gtapService';
import OnboardingProcessProvider from '@context/OnboardingProcessProvider';
import { useMutation } from '@tanstack/react-query';
import LottieComponent from '@components/Lottie';
import InvitationExpired from '@components/InvitationExpired/InvitationExpired';
import {
  JoinOrganizationErrorEmailDoesNotMatchDto,
  JoinOrganizationErrorEmailDomainNotAllowedDto
} from '@api/Api';
import InvitationEmailMismatch from '@components/InvitationEmailMismatch/InvitationEmailMismatch';
import { ErrorPage500 } from '@pages/ErrorPage';
import InvitationDomainMismatch from '@components/InvitationDomainMismatch/InvitationDomainMismatch';

const AppPages = React.lazy(() => import('@pages'));
const AsanaOAuthInstall = React.lazy(() => import('@pages/OAuth/AsanaIntsall'));

interface AuthorizedAppProps {
  loader: ReactElement;
}

export type ErrorMessageProps = {
  title: string | undefined;
  description: string;
};

export default function AuthorizedApp(props: AuthorizedAppProps) {
  const [searchParams] = useSearchParams();
  const dispatch = useTypedDispatch();

  const ownOrganization = useTypedSelector((state) =>
    ownOrganizationItemStateSelector(state)
  );
  const organizationsCount = useTypedSelector(
    (state) => organizationsStateSelector(state).list.count
  );
  const list = useTypedSelector((state) =>
    organizationItemsListStateSelector(state)
  );

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const { loader } = props;
  const { user } = useAuth(true, 'internal');
  const mixpanel = useMixpanel();
  const [displayLinkedInPixel, setDisplayLinkedInPixel] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps>();
  const [demoCampaignId, setDemoCampaignId] = useState<string>();
  const userNameRef = useRef(user.name);
  userNameRef.current = user.name;
  useEffect(() => {
    const _demoCampaignId = searchParamsRef.current.get('demoCampaignId');
    if (_demoCampaignId) {
      setDemoCampaignId(_demoCampaignId);
    }

    const errorCode = searchParamsRef.current.get('errorCode');
    if (errorCode && errorCode === 'email_does_not_match') {
      const actualEmail = searchParamsRef.current.get('actualEmail');
      const invitationEmail = searchParamsRef.current.get('invitationEmail');
      const content = `You're currently logged in as ${actualEmail}. To join the workspace you've been invited to, log out of StreamWork and log back in with ${invitationEmail}`;
      setErrorMessage({ title: undefined, description: content });
    }

    if (errorCode && errorCode === 'secret_expired') {
      const title = 'The invite has expired';
      const content =
        'Please contact the workspace owner to resend the invitation.';
      setErrorMessage({ title, description: content });
    }
  }, []);

  useEffect(() => {
    if (!organizationsCount) return;
    const sendLoginEvent = sessionStorage.getItem('send-login-event');
    if (!sendLoginEvent) return;
    sessionStorage.removeItem('send-login-event');
    const planType = ownOrganization?.planType || 'free';
    if (!user.isNewUser) {
      MixpanelService.track(mixpanel, user.id, MixpanelEventType.SIGN_IN, {
        planType,
        email: user.email,
        name: user.name
      });
    }
  }, [mixpanel, user, organizationsCount, ownOrganization]);

  useEffect(() => {
    setDisplayLinkedInPixel(!!user.isNewUser);
    if (!user.isNewUser) return;
    GtagService.trackUserRegistration({ name: userNameRef.current });
  }, [user.isNewUser]);

  const loading = useFetch({
    key: `organizations`,
    selector: (state) => organizationListStateSelector(state).fetch,
    disabled: !user.emailVerified,
    fetch: (fetchType) => dispatch(fetchOrganizationsList({ fetchType }))
  });

  if (loading) {
    return loader;
  }
  if (!user.emailVerified) {
    return <EmailConfirmation />;
  }
  return (
    <OrganizationProvider organizations={list}>
      {displayLinkedInPixel && (
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=4670220&conversionId=13829836&fmt=gif"
        />
      )}
      <OnboardingProcessProvider>
        <Routes>
          <Route path="/accept-invite" element={<AcceptInvite />} />
          <Route path="/card/success" element={<CardSuccess />} />
          <Route
            path="/subscription/success"
            element={<SuccessSubscription />}
          />
          <Route path="/lock" element={<OrganizationLocked />} />
          <Route path="/onboarding" element={<OnboardingSteps />} />
          <Route
            path="/oauth/asana/install"
            element={<AsanaOAuthInstall loader={loader} />}
          />
          <Route element={<OrganizationBoundary loader={loader} />}>
            <Route
              path="/*"
              element={
                <AppPages
                  demoCampaignId={demoCampaignId}
                  loader={loader}
                  errorMessage={errorMessage}
                />
              }
            />
          </Route>
        </Routes>
      </OnboardingProcessProvider>
    </OrganizationProvider>
  );
}

function CardSuccess() {
  const { currentOrganization } = useOrganization();

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await subscriptionSetCardCreated({
        organizationId: nonNull(currentOrganization.entity?.id)
      });
      navigate('/admin-settings/billing', { replace: true });
    })();
  }, [currentOrganization.entity?.id, navigate]);
  return null;
}

function SuccessSubscription() {
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const updated = searchParams.get('updated');

  useEffect(() => {
    // eslint-disable-next-line func-names
    (async function () {
      if (updated) return;
      if (!currentOrganization?.entity) return;
      try {
        await setSubscriptionCreated({
          organizationId: currentOrganization.entity.id
        });
      } finally {
        setSearchParams((prev) => {
          prev.set('updated', 'true');
          return prev;
        });
        window.location.reload();
      }
    })();
  }, [updated, setSearchParams, currentOrganization]);

  useEffect(() => {
    if (!updated) return;
    if (!currentOrganization?.entity) return;
    if (!currentOrganization?.subscription) {
      navigate('/campaigns/all', { replace: true });
      return;
    }
    const plan = currentOrganization.entity?.planType || '';
    message.success({
      key: 'change-plan-modal',
      content: `Welcome to the StreamWork ${
        plan[0].toUpperCase() + plan.slice(1)
      } plan!`,
      icon: <SuccessSvg className="anticon" />,
      className: 'message-dark-modal',
      duration: 3
    });
    navigate('/campaigns/all', { replace: true });
  }, [updated, currentOrganization, navigate]);
  return null;
}

function AcceptInvite() {
  const [searchParams] = useSearchParams();
  const ticket = searchParams.get('ticket');
  const { mutate, error, isError } = useMutation({
    mutationFn: joinToOrganization,
    onSuccess: () => {
      const r = searchParams.get('r');
      if (r) window.location.href = r;
      else window.location.pathname = '/';
    }
  });
  useEffect(() => {
    if (ticket) mutate(ticket);
  }, [mutate, ticket]);

  const errorCode: JoinOrganizationError['errorCode'] | undefined = !ticket
    ? 'secret_expired'
    : (error as any)?.response?.data?.errorCode;

  if (errorCode === 'secret_expired' || errorCode === 'secret_does_not_match') {
    return <InvitationExpired />;
  }
  if (errorCode === 'email_does_not_match') {
    const payload: JoinOrganizationErrorEmailDoesNotMatchDto = (error as any)
      .response.data;
    return (
      <InvitationEmailMismatch
        invitationEmail={payload.invitationEmail}
        actualEmail={payload.actualEmail}
      />
    );
  }
  if (errorCode === 'email_domain_not_allowed') {
    const payload: JoinOrganizationErrorEmailDomainNotAllowedDto = (
      error as any
    ).response.data;
    return (
      <InvitationDomainMismatch
        email={payload.email}
        allowedDomains={payload.allowedDomains}
      />
    );
  }
  if (isError) {
    return <ErrorPage500 />;
  }
  return <LottieComponent className="page_spinner" view="preloader" />;
}
